<template>
  <b-modal
    id="booking-cancel-modal"
    v-model="modal.show"
    centered
    title="Cancelling appointment"
    modal-class="modal-primary"
    @hidden="cancel"
  >
    <booking-service-cancellation-policy
      v-if="cancellationPolicy.chargeFee > 0 && cancellationPolicy.timeToCancel > 0"
      :charge-fee="cancellationPolicy.chargeFee"
      :cancellation-time="cancellationPolicy.timeToCancel"
    />

    <h2>Are you sure?</h2>

    <template #modal-footer="{}">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        No
      </b-button>
      <b-button
        variant="primary"
        @click="accept()"
      >
        Yes
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue'
import {
  CANCELLATION_POLICY,
  HOURS_BEFORE_APPOINTMENT_POLICY,
} from '@/dictionaries/stylistPolicyConfigDictionary'
import BookingServiceCancellationPolicy from '@/components/booking/booking-service/BookingServiceCancellationPolicy.vue'

export default {
  name: 'BookingCancelModal',
  components: {
    BModal,
    BButton,
    BookingServiceCancellationPolicy,
  },
  props: {
    show: {
      type: Boolean,
      default: () => true,
    },
    cancel: {
      type: Function,
      default: () => () => {},
    },
    accept: {
      type: Function,
      default: () => () => {},
    },
    appointmentPolicies: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      modal: {
        show: this.show,
      },
    }
  },
  computed: {
    cancellationPolicy() {
      return {
        chargeFee: this.appointmentPolicies[CANCELLATION_POLICY]?.value || 0,
        timeToCancel: this.appointmentPolicies[HOURS_BEFORE_APPOINTMENT_POLICY]?.value || 0,
      }
    },
  },
  mounted() {
    this.modal.show = true
  },
}
</script>

<style lang="scss">
#booking-cancel-modal {
  h2 {
    text-align: center;
  }

  .card {
    background-color: transparent;

    box-shadow: none;
    .card-body {
      padding: 15px;

      p {
        text-align: justify;
      }
    }
  }
}
</style>
