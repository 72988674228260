<template>
  <tbb-card title="Open Hours">
    <!-- Open hours -->
    <b-list-group
      flush
      class="mt-2"
    >
      <b-list-group-item
        v-for="(day) in openHoursData"
        :key="day.day"
        :class="[{
          'closed': !day.openHours
        }]"
        class="open-hour-item"
      >
        <b-row align-v="baseline">
          <b-col cols="auto">
            <span class="label">{{ day.dayName }}: </span>
          </b-col>
          <b-col class="dots" />
          <b-col cols="auto">
            <span v-if="!day.openHours">Closed</span>
            <span
              v-for="(hours, index) in day.openHours"
              v-else
              :key="index"
            >
              {{ getHour(hours.startTime) }} - {{ getHour(hours.endTime) }}<span v-if="index+1 < day.openHours.length">,<br> </span>
            </span>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <!-- Open hours -->
  </tbb-card>
</template>

<script>
import {
  BListGroup, BListGroupItem, BCol, BRow,
} from 'bootstrap-vue'
import moment from 'moment'
import { mapState } from 'vuex'
import TbbCard from '../../sites/TbbCard.vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BCol,
    BRow,
    TbbCard,
  },
  props: {
    openHoursData: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('StylistPageStoreModule', {
      stylistStore: state => state.stylist,
    }),
  },
  methods: {
    getHour(date) {
      return moment(date, 'HH:mm').format('h:mma')
    },
  },
}
</script>
<style lang="scss" scoped>
.label {
  font-weight: bold;
}
.dots{
  border-bottom: 2px dotted #ddd;
}
.open-hour-item {
  padding: 0.7rem 0.7rem 0.7rem 0;
  border-bottom: none;
}
</style>
