<template>
  <div>
    <h5>Cancellation Policy</h5>
    <p>Please note that once you have booked an appointment with us it means that we have reserved time in our schedule exclusively for you. If you cancel your appointment <b>less than {{ timeToCancel.toFixed(2) }} hours</b> before it is scheduled to take place, you will be subject to a <b>fee charge of {{ chargeFee }}%</b>. To avoid a cancellation fee, please provide cancellation notice <b>at least {{ timeToCancel.toFixed(2) }} hours</b> prior to your appointment.</p>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  props: {
    chargeFee: {
      type: Number,
      default: 0,
    },
    cancellationTime: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      timeToCancel: moment.duration(+this.cancellationTime, 'minutes').asHours(),
    }
  },
}
</script>

<style scoped>
div{
  margin-top: 0.5rem;
}
p{
  font-size: 12px;
}
</style>
