<template>
  <b-overlay
    :show="stylistData.isPending || appointmentsPending"
    bg-color="#f8f8f8"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="lg"
    class="min-vh-100"
  >
    <div id="user-profile">
      <stylist-page-header :header-data="stylistData.header" />
      <b-container class="pb-2 py-md-4 px-sm-2 px-lg-4">
        <b-row>
          <b-col xl="9">
            <!-- appointments -->
            <section id="appointments">
              <b-row>
                <b-col v-if="appointments.length === 0">
                  <b-card>
                    <b-card-body class="text-center">
                      No appointments yet!
                    </b-card-body>
                  </b-card>
                </b-col>

                <!-- appointment card -->
                <b-col
                  v-for="(appointment, index) in appointments"
                  :key="index"
                  lg="4"
                  md="6"
                  class="col-item"
                >
                  <b-card class="card-meetup">

                    <div class="meetup-header">
                      <b-img
                        fluid
                        :src="meetingImg"
                        alt="meeting"
                        class="rounded-top"
                      />
                      <div :class="'meetup-status ' + BOOKING_STATUSES[appointment.status].toLowerCase().replace(/\s/g, '-')">
                        <span class="rounded-bottom">{{ BOOKING_STATUSES[appointment.status] }}</span>
                      </div>
                    </div>

                    <div class="meetup-date rounded-top">
                      <h6 class="mb-0">
                        {{ appointment.dateFull }}
                      </h6>
                      <small>{{ appointment.timeStart }} to {{ appointment.timeEnd }}</small>
                    </div>

                    <div class="meetup-services text-center">
                      <h5 class="services-label">
                        Services:
                      </h5>
                      <p>
                        <span
                          v-for="(service, sIndex) in appointment.services"
                          :key="sIndex"
                        >{{ service.name }}</span>
                      </p>
                    </div>

                    <!--
                    <div class="media">
                      <div class="media-aside mr-1 align-self-start"><span class="b-avatar badge-light-primary
                      rounded" style="width: 34px; height: 34px;"><span class="b-avatar-custom"><svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg></span></span></div>
                      <div class="media-body">
                        <h6 class="mb-0">Central Park</h6>
                        <small>Manhattan, New york City</small>
                      </div>
                    </div>
                    -->

                    <div class="mt-auto p-2 text-center">
                      <b-button
                        v-if="['cancelled', 'no_show', 'in_progress', 'paid'].indexOf(appointment.status) < 0"
                        variant="primary"
                        @click="selectAppointment(appointment)"
                      >
                        Cancel
                      </b-button>

                      <b-button
                        v-else
                        variant="outline-secondary"
                        disabled
                      >
                        <span v-if="appointment.status === 'in_progress'">
                          In progress
                        </span>

                        <span v-else-if="appointment.status === 'paid'">
                          Paid
                        </span>

                        <span v-else>
                          Cancelled
                        </span>
                      </b-button>
                      <b-button
                        v-if="appointment.status === 'paid' && !appointment.review"
                        class="ml-1"
                        variant="outline-primary"
                        @click="writeReview(appointment.uuid)"
                      >
                        <span>
                          Write a Review
                        </span>
                      </b-button>
                    </div>

                  </b-card>
                </b-col>
                <!-- /appointment card -->
              </b-row>
            </section>
          </b-col>

          <b-col
            xl="3"
            class="second-column"
          >
            <stylist-page-open-hours :open-hours-data="openHours" />
          </b-col>
        </b-row>
        <tbb-card
          class="text-center mt-5 pt-2 centered"
          title="Contact"
        >
          <b-card class="mt-2 custom-card">
            <stylist-page-contact :contact-data="stylistData.contact" />
            <stylist-page-social-media :social-media-data="stylistData.socialMedia" />
          </b-card>
        </tbb-card>
      </b-container>
    <!-- /appointments -->
    </div>

    <booking-cancel-modal
      v-if="selectedAppointment !== null"
      :show="true"
      :accept="() => cancel(selectedAppointment)"
      :cancel="() => selectAppointment(null)"
      :appointment-policies="selectedAppointment.policies"
    />
  </b-overlay>
</template>

<script>
import {
  BImg, BRow, BCol, BOverlay, BCard, BCardBody, BButton, BContainer,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'

import BOOKING_STATUSES from '@/dictionaries/bookingStatusDictionary'

import StylistPageHeader from '@/components/stylist/stylist-page/StylistPageHeader.vue'
import StylistPageContact from '@/components/stylist/stylist-page/StylistPageContact.vue'
import StylistPageOpenHours from '@/components/stylist/stylist-page/StylistPageOpenHours.vue'
import StylistPageSocialMedia from '@/components/stylist/stylist-page/StylistPageSocialMedia.vue'
import { APPOINTMENT_REVIEW } from '@/router/routes/routes-names'
import AppointmentImage from '../../../assets/images/pages/appointment-img.jpg'
import BookingCancelModal from './BookingCancelModal.vue'
import TbbCard from '../../sites/TbbCard.vue'

/* eslint-disable global-require */
export default {
  components: {
    BImg,
    BRow,
    BCol,
    BOverlay,
    BCard,
    BCardBody,
    BButton,
    BContainer,
    StylistPageHeader,
    StylistPageContact,
    StylistPageOpenHours,
    StylistPageSocialMedia,
    BookingCancelModal,
    TbbCard,
  },
  data() {
    return {
      BOOKING_STATUSES,
      meetingImg: AppointmentImage,
      selectedAppointment: null,
    }
  },
  computed: {
    ...mapState('StylistPageStoreModule', {
      stylistData: state => {
        const response = state?.stylist?.response

        return {
          header: {
            avatar: require('@/assets/images/user-uploads/stylist-profile/avatars/avatar-s-2.jpg'),
            businessName: response?.businessName,
            coverImg: require('@/assets/images/user-uploads/stylist-profile/backgrounds/timeline.jpg'),
          },

          description: response?.businessDescription,

          // Contact
          contact: {
            businessName: response?.businessName,
            email: response?.email,
            address: response?.address,
            city: response?.city,
            postCode: response?.postCode,
          },

          // Social media
          socialMedia: {
            facebookUrl: response?.facebookUrl,
            twitterUrl: response?.twitterUrl,
            instagramUrl: response?.instagramUrl,
          },

          response,
          isPending: state?.stylist?.isPending,
          error: state?.stylist?.error,
        }
      },
      openHours: state => state.openHours.response || [],
    }),
    ...mapState('BookingServiceStoreModule', {
      appointments: state => (state.appointments.response || []).map(a => {
        const date = moment(a.startsAt, 'yyyy-MM-DD HH:mm:ss')
        const dateEnd = moment(a.endsAt, 'yyyy-MM-DD HH:mm:ss')
        const policies = JSON.parse(a.stylistPolicies).reduce((obj, item) => ({
          ...obj,
          [item.stylistPolicyConfigDictionary]: item,
        }), {})

        return {
          ...a,
          dateWeekday: date.format('ddd'),
          dateDay: date.format('D'),
          dateFull: date.format('ddd, MMM D, YYYY'),
          timeStart: date.format('H:mm A'),
          timeEnd: dateEnd.format('H:mm A'),
          policies,
        }
      }),
      appointmentsPending: state => state.appointments.isPending,
    }),
  },
  mounted() {
    if (!this.stylistData.header.businessName) {
      this.fetchPublicStylist(this.$store.getters['app/getSubdomain'])
    }

    if (!this.appointmentsPending && this.appointments.length === 0) {
      this.fetchAppointments()
    }

    if (this.openHours.length === 0) {
      this.fetchPublicOpenHours()
    }
  },
  methods: {
    ...mapActions('StylistPageStoreModule', [
      'fetchPublicStylist',
      'fetchPublicOpenHours',
    ]),
    ...mapActions('BookingServiceStoreModule', [
      'fetchAppointments',
      'cancelAppointment',
    ]),
    selectAppointment(appointment) {
      this.selectedAppointment = appointment
    },
    cancel(appointment) {
      this.selectAppointment(null)
      this.cancelAppointment(appointment).then(() => this.fetchAppointments())
    },
    writeReview(appointmentUuid) {
      this.$router.push({ name: APPOINTMENT_REVIEW, params: { appointmentUuid } })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-profile.scss";
@media (min-width: 992px){
  .second-column{
    align-self: flex-start;
    position: sticky;
    top: 1rem;
  }
}
</style>
<style lang="scss" scoped>
.col-item{
  margin-bottom: 2rem;
}
.custom-card{
  width: 100%;
  max-width: 350px;
}
.card-meetup{
  margin-bottom: 0;
  height: 100%;
  .card-body{
    padding: 0;
    display: flex;
    flex-direction: column;
  }
}
.meetup-header{
  position: relative;
}
.meetup-status{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  span{
    display: inline-block;
    color: #ffffff;
    background-color: #888;
    padding: 0.25rem 1rem;
    font-weight: 600;
    font-size: 0.85rem;
  }
  &.cancelled span{
    background-color: #000;
  }
  &.pending span{
    background-color: #7367f0;
  }
  &.confirmed span{
    background-color: #ed8d8b;
  }
  &.paid span{
    background-color: #7eb0eb;
  }
  &.in-progress span{
    background-color: #528d64;
  }
}
.meetup-date{
  text-align: center;
  font-size: 1.5rem;
  background-color: #fff;
  width: 80%;
  margin: -2rem auto 0;
  position: relative;
  padding: 1rem;
  h6{
    font-size: 1em;
  }
  small{
    font-size: 0.75em;
  }
}
.meetup-services{
  h5.services-label{
    color: #aaa;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 400;
  }
  P{
    margin-top: 0.5rem;
    margin-bottom: 0;
    font-size: 1.125rem;
    font-weight: 600;
    padding: 0 1rem;
  }
  span:not(:last-child):after{
    content: ', ';
  }
}
</style>
