<template>
  <div class="d-inline-flex justify-center mb-1">
    <div
      v-if="socialMediaData.facebookUrl"
      class="ml-1 mt-1 mr-1"
    >
      <a
        :href="socialMediaData.facebookUrl"
        rel="noreferrer"
        target="_blank"
      >
        <feather-icon
          icon="FacebookIcon"
          size="36"
        />
      </a>
    </div>
    <div
      v-if="socialMediaData.twitterUrl"
      class="ml-1 mt-1 mr-1"
    >
      <a
        :href="socialMediaData.twitterUrl"
        rel="noreferrer"
        target="_blank"
      >
        <feather-icon
          icon="TwitterIcon"
          size="36"
        />
      </a>
    </div>
    <div
      v-if="socialMediaData.instagramUrl"
      class="ml-1 mt-1 mr-1"
    >
      <a
        :href="socialMediaData.instagramUrl"
        rel="noreferrer"
        target="_blank"
      >
        <feather-icon
          icon="InstagramIcon"
          size="36"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    socialMediaData: {
      type: Object,
      default: () => [],
    },
  },
}
</script>
<style lang="scss" scoped>
a svg{
  stroke-width: 1px;
}
</style>
