<template>
  <div class="tbb-card">
    <header class="d-flex justify-content-between">
      <h2 class="tbb-card-title">
        {{ title }}
      </h2>
      <slot name="extraHeader" />
    </header>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
</script>
<style lang="scss">
.tbb-card{
  &-title{
    text-transform: uppercase;
    color: #aaa;
    font-weight: 400;
    letter-spacing: 0.1em;
    font-size: 1.25rem;
    &:after{
      content: '';
      display: block;
      height: 2px;
      width: 100px;
      max-width: 50%;
      background-color: #d1b08f;
      margin-top: 1rem;
    }
  }
  .list-group-item{
    background-color: transparent;
  }
  &.centered{
    display: flex;
    flex-direction: column;
    align-items: center;
    .tbb-card-title:after{
      margin: 1rem auto 0;
    }
  }
}
</style>
