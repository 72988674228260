<template>
  <div>
    <h1 class="my-1">
      {{ contactData.businessName }}
    </h1>
    <div v-if="contactData.email">
      <p>
        {{ contactData.email }}
      </p>
    </div>

    <div v-if="contactData.address || contactData.city">
      <h5 class="text-capitalize mb-75 mt-2">
        Address
      </h5>
      <p>
        {{ contactData.address }}<br>
        <span v-if="contactData.postCode">{{ contactData.postCode }},</span> {{ contactData.city }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contactData: {
      type: Object,
      default: () => [],
    },
  },
}
</script>
